import '../js/bootstrap.js';

import '../scss/main.scss';

// Require jQuery (Fancybox dependency)
window.$ = window.jQuery = require('jquery');

// Fancybox
const fancybox = require('@fancyapps/fancybox/');
// Fancybox Stylesheet

const fancyboxCSS = require('@fancyapps/fancybox/dist/jquery.fancybox.css');

    
   function onEntry(entry) {
    entry.forEach(change => {
      if (change.isIntersecting) {
        change.target.classList.add('element-show');
      }
    });
  }
  let options = { threshold: [0.1] };
  let observer = new IntersectionObserver(onEntry, options);
  let elements = document.querySelectorAll('.element-animation');
  for (let elm of elements) {
    observer.observe(elm);
  }

  $(document).ready(function(){
    $("a[href*='#']").on("click", function(e){
      var anchor = $(this);
      $('html, body').stop().animate({
        scrollTop: $(anchor.attr('href')).offset().top
      }, 777);
      e.preventDefault();
      return false;
    });     
});

jQuery.urlParam = function(name){
           var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
           if (results==null) {
               return null;
           }
           return decodeURI(results[1]) || 0;
}
var url_p1 = '';
url_p1 = jQuery.urlParam('p1');

var utm_source = jQuery.urlParam('utm_source');
var utm_medium = jQuery.urlParam('utm_medium');
var utm_campaign = jQuery.urlParam('utm_campaign');
var utm_content = jQuery.urlParam('utm_content');
var utm_term = jQuery.urlParam('utm_term');
/*   
console.log(utm_source)
console.log(utm_medium)
console.log(utm_campaign)
console.log(utm_content)
console.log(utm_term)
*/

var salebot_url = "https://salebot.site/md/money_i_deti" + "?utm_source=" + utm_source + "&utm_medium=" + utm_medium + "&utm_campaign=" + utm_campaign + "&utm_content=" + utm_content + "&utm_term=" + utm_term
$("#salebot").attr('href',salebot_url)

  $( "#promokod_btn_1,#promokod_btn_2" ).click(function() {
    $( "#promokod_block" ).show();
  });  
  
  var promokod = ''
  $( "#promokod_check" ).click(function() {
    promokod = $( "#promokod_input" ).val();
    
    if(promokod == 'Modelium-Finance' || promokod == 'Kris_bond_event' || promokod == 'галина22' || promokod == 'Галина22' || promokod == 'ГАЛИНА22' || promokod == 'ОСОЗНАНИЕ' || promokod == 'Осознание' || promokod == 'осознание' || promokod == 'Адлер' || promokod == 'адлер' || promokod == 'АДЛЕР' || promokod == 'Алмаз' || promokod == 'aлмаз' || promokod == 'АЛМАЗ' || promokod == 'Натали' || promokod == 'натали' || promokod == 'НАТАЛИ' || promokod == 'Гармония' || promokod == 'гармония' || promokod == 'ГАРМОНИЯ' || promokod == 'Прокопенко' || promokod == 'прокопенко' || promokod == 'ПРОКОПЕНКО' || promokod == 'Декабрь' || promokod == 'ДЕКАБРЬ' || promokod == 'декабрь' || promokod == 'MoreInvest' || promokod == 'moreinvest'){
      $( "#promokod_block" ).hide();
      $( "#promokod_btn_1,#promokod_btn_2" ).hide();
      $( ".tarif_1,.tarif_2" ).css('text-decoration','line-through');
      $( ".tarif_1" ).after('<span class="tarif__value"></br>30 000 рублей</span>');
      $( ".tarif_2" ).after('<span class="tarif__value"></br>15 000 рублей</span>');
      $( "#oplata_1, #oplata_2" ).hide();
      $( "#oplata_promokod_1, #oplata_promokod_2" ).show();
      if(promokod == 'Modelium-Finance'){
        $('#promokod_offline').attr('href','https://payform.ru/6r1wMaI/')
        $('#promokod_online').attr('href','https://payform.ru/6r1wMbY/')
      };
      if(promokod == 'Kris_bond_event'){
        $('#promokod_offline').attr('href','https://payform.ru/r61wM8V/')
        $('#promokod_online').attr('href','https://payform.ru/t71wMa0/')
      };  
      if(promokod == 'галина22' || promokod == 'Галина22' || promokod == 'ГАЛИНА22'){
        $('#promokod_offline').attr('href','https://payform.ru/oj1wM7x/')
        $('#promokod_online').attr('href','https://payform.ru/pr1wM8c/')
      };  
      if(promokod == 'ОСОЗНАНИЕ' || promokod == 'Осознание' || promokod == 'осознание'){
        $('#promokod_offline').attr('href','https://payform.ru/lb1wM5O/')
        $('#promokod_online').attr('href','https://payform.ru/n41wM6L/')
      };  
      if(promokod == 'Адлер' || promokod == 'адлер' || promokod == 'АДЛЕР'){
        $('#promokod_offline').attr('href','https://payform.ru/hu1wM3Z/')
        $('#promokod_online').attr('href','https://payform.ru/k11wM57/')
      };  
      if(promokod == 'Алмаз' || promokod == 'aлмаз' || promokod == 'АЛМАЗ'){
        $('#promokod_offline').attr('href','https://payform.ru/dw1wM1T/')
        $('#promokod_online').attr('href','https://payform.ru/g91wM37/')
      };  
      if(promokod == 'Натали' || promokod == 'натали' || promokod == 'НАТАЛИ'){
        $('#promokod_offline').attr('href','https://payform.ru/9u1wLZJ/')
        $('#promokod_online').attr('href','https://payform.ru/c91wM0Z/')
      };  
      if(promokod == 'Гармония' || promokod == 'гармония' || promokod == 'ГАРМОНИЯ'){
        $('#promokod_offline').attr('href','https://payform.ru/5n1wLXu/')
        $('#promokod_online').attr('href','https://payform.ru/861wLYO/')
      };  
      if(promokod == 'Прокопенко' || promokod == 'прокопенко'|| promokod == 'ПРОКОПЕНКО'){
        $('#promokod_offline').attr('href','https://payform.ru/6r1wLVe/')
        $('#promokod_online').attr('href','https://payform.ru/351wLW8/')
      };  
  
      if(promokod == 'Декабрь' || promokod == 'декабрь'|| promokod == 'ДЕКАБРЬ'){
        $('#promokod_offline').attr('href','https://payform.ru/pm1wLRZ/')
        $('#promokod_online').attr('href','https://payform.ru/t31wLTO/')
      };  
      if(promokod == 'MoreInvest' || promokod == 'moreinvest'){
        $('#promokod_offline').attr('href','https://payform.ru/m21wLjO/')
        $('#promokod_online').attr('href','https://payform.ru/mp1wLQr/')
      };  
 
    } else {
      $( "#promokod_text" ).text("Неверный промокод");
    }
  });
  $( ".promokod_block_close" ).click(function() {
    $( "#promokod_block" ).hide();
  });
   


$(document).ready(function(){
var maxHeight = 0;
var highestElement = {};
$('.tarif__list-item').each(function() {
    var thisHeight = $(this).outerHeight();
    if (thisHeight>maxHeight) {
        maxHeight = thisHeight;
        highestElement = $(this);
    }
});
$('.tarif__list-item').css('min-height',maxHeight);
});

$(window).scroll(function() {
  if ( $(window).scrollTop() >= 0 ){
    $('.header').css('background-color', 'rgba(210, 208, 208, 1)');
  }
  if ( $(window).scrollTop() == 0 ){
    $('.header').removeAttr('style');
  }
});
